import http from "../util/httpService";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

export async function login(userName, password, rememberMe, from) {
  try {
    // Login Request
    const LoginReq = await http.post("Authenticate/Login", { userName, password });
    // Decode Token
    const decoded = jwt_decode(LoginReq.token);
    const FutureTime = new Date(decoded.exp * 1000);
    const CurrentTime = new Date();

    if (rememberMe) {
      // get total seconds between the times
      var delta = Math.abs(FutureTime - CurrentTime) / 1000;
      // calculate (and subtract) whole days
      var days = delta / 86400;
      // calculate (and subtract) whole hours
      var hours = Math.ceil(delta / 3600);

      // If Remember Me Store in Cookies
      // Set User Credentials
      Cookies.set("SQUserToken", LoginReq.token, {
        expires: days,
      });
      Cookies.set("SQUserPermissions", decoded.Claim, {
        expires: days,
      });
      Cookies.set("SQUser", JSON.stringify(LoginReq.user), {
        expires: days,
      });
    } else {
      // Not Remember Me Save in Cookie as Session
      // Set User Credentials
      Cookies.set("SQUserToken", LoginReq.token);
      Cookies.set("SQUserPermissions", decoded.Claim);
      Cookies.set("SQUser", JSON.stringify(LoginReq.user));
    }

    // Redirect To Dashboard
    window.location = from;
  } catch (error) {
    toast.error(error.response.data ? error.response.data[0] : null);
  }
}

export function getJwt() {
  return Cookies.get("SQUserToken");
}

export default {
  login,
  // logout,
};
