import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export const userPermissions = Cookies.get("SQUserPermissions")
  ? Cookies.get("SQUserPermissions").split(",")
  : [];

const isSuperAdmin = Cookies.get("SQUserToken")
  ? jwt_decode(Cookies.get("SQUserToken")).TenantId === "00000000-0000-0000-0000-000000000000"
  : false;

export const PERMISSIONS = isSuperAdmin
  ? [
      "Permissions.Tenant.View",
      "Permissions.Tenant.Insert",
      "Permissions.Tenant.Edit",
      "Permissions.Tenant.Delete",
      "Permissions.Employee.View",
      "Permissions.Employee.Insert",
      "Permissions.Employee.Edit",
      "Permissions.Employee.Delete",
      "Permissions.Country.View",
      "Permissions.Attachment.View",
      "Permissions.Attachment.Downlaod",
      "Permissions.Attachment.Insert",
      "Permissions.Attachment.Delete",
      "Permissions.City.View",
      "Permissions.State.View",
      "Permissions.User.View",
      "Permissions.User.Insert",
      "Permissions.User.Edit",
      "Permissions.User.Delete",
      "Permissions.Zone.View",
      "Permissions.Zone.Insert",
      "Permissions.Zone.Edit",
      "Permissions.Zone.Delete",
      "Permissions.Role.View",
      "Permissions.Role.Insert",
      "Permissions.Role.Edit",
      "Permissions.Role.Delete",
      "Permissions.Vehicle.View",
      "Permissions.Vehicle.Insert",
      "Permissions.Vehicle.Edit",
      "Permissions.Vehicle.Delete",
      "Permissions.Customer.View",
      "Permissions.Customer.Insert",
      "Permissions.Customer.Edit",
      "Permissions.Customer.Delete",
      "Permissions.Warehouse.View",
      "Permissions.Warehouse.Insert",
      "Permissions.Warehouse.Edit",
      "Permissions.Warehouse.Delete",
      "Permissions.Shipment.View",
      "Permissions.Shipment.Insert",
      "Permissions.Shipment.Edit",
      "Permissions.Shipment.Delete",
      "Permissions.PriceList.View",
      "Permissions.PriceList.Insert",
      "Permissions.PriceList.Edit",
      "Permissions.PriceList.Delete",
      "Permissions.Task.View",
      "Permissions.Task.Insert",
      "Permissions.Task.Edit",
      "Permissions.Task.Start",
      "Permissions.Task.Complete",
      "Permissions.Task.Operation",
      "Permissinos.Email.View",
      "Permissinos.Email.Edit",
    ]
  : [
      "Permissions.Tenant.View",
      "Permissions.Vehicle.Insert",
      "Permissions.Vehicle.Edit",
      "Permissions.Vehicle.Delete",
      "Permissions.Customer.View",
      "Permissions.Customer.Insert",
      "Permissions.Customer.Edit",
      "Permissions.Customer.Delete",
      "Permissions.Warehouse.View",
      "Permissions.Warehouse.Insert",
      "Permissions.Warehouse.Edit",
      "Permissions.Warehouse.Delete",
      "Permissions.Shipment.View",
      "Permissions.Shipment.Insert",
      "Permissions.Shipment.Edit",
      "Permissions.Shipment.Delete",
      "Permissions.PriceList.View",
      "Permissions.PriceList.Insert",
      "Permissions.PriceList.Edit",
      "Permissions.PriceList.Delete",
      "Permissions.Task.View",
      "Permissions.Task.Insert",
      "Permissions.Task.Edit",
      "Permissions.Task.Start",
      "Permissions.Vehicle.View",
      "Permissions.Task.Complete",
      "Permissions.Role.Delete",
      "Permissions.Role.Insert",
      "Permissions.Tenant.Insert",
      "Permissions.Tenant.Edit",
      "Permissions.Tenant.Delete",
      "Permissions.Employee.View",
      "Permissions.Employee.Insert",
      "Permissions.Employee.Edit",
      "Permissions.Employee.Delete",
      "Permissions.Country.View",
      "Permissions.Attachment.View",
      "Permissions.Attachment.Downlaod",
      "Permissions.Attachment.Insert",
      "Permissions.Attachment.Delete",
      "Permissions.City.View",
      "Permissions.State.View",
      "Permissions.User.View",
      "Permissions.User.Insert",
      "Permissions.User.Edit",
      "Permissions.User.Delete",
      "Permissions.Zone.View",
      "Permissions.Zone.Insert",
      "Permissions.Zone.Edit",
      "Permissions.Zone.Delete",
      "Permissions.Role.View",
      "Permissions.Role.Edit",
      "Permissions.Task.Operation",
    ];

export const MAPPED_PERMISSIONS = {
  Tenant: [
    "Permissions.Tenant.View",
    "Permissions.Tenant.Insert",
    "Permissions.Tenant.Edit",
    "Permissions.Tenant.Delete"
  ],
  Employee: [  
    "Permissions.Employee.View",
    "Permissions.Employee.Insert",
    "Permissions.Employee.Edit",
    "Permissions.Employee.Delete"
  ],
  Country: ["Permissions.Country.View"],
  City: ["Permissions.City.View"],
  State: ["Permissions.State.View"],
  Attachmnet: [  
    "Permissions.Attachment.View",
    "Permissions.Attachment.Downlaod",
    "Permissions.Attachment.Insert",
    "Permissions.Attachment.Delete"
  ],
  User: [  
      "Permissions.User.View",
    "Permissions.User.Insert",
    "Permissions.User.Edit",
    "Permissions.User.Delete"
  ],
  Zone: [  
    "Permissions.Zone.View",
    "Permissions.Zone.Insert",
    "Permissions.Zone.Edit",
    "Permissions.Zone.Delete"
  ],
  Role: [  
    "Permissions.Role.View",
    "Permissions.Role.Insert",
    "Permissions.Role.Edit",
    "Permissions.Role.Delete"
  ],
  Vehicle: [  
    "Permissions.Vehicle.View",
    "Permissions.Vehicle.Insert",
    "Permissions.Vehicle.Edit",
    "Permissions.Vehicle.Delete"
  ],
  Customer: [  
    "Permissions.Customer.View",
    "Permissions.Customer.Insert",
    "Permissions.Customer.Edit",
    "Permissions.Customer.Delete"
  ],
  Warehouse: [  
    "Permissions.Warehouse.View",
    "Permissions.Warehouse.Insert",
    "Permissions.Warehouse.Edit",
    "Permissions.Warehouse.Delete"
  ],
  Shipment: [  
    "Permissions.Shipment.View",
    "Permissions.Shipment.Insert",
    "Permissions.Shipment.Edit",
    "Permissions.Shipment.Delete"
  ],
  Pricelist: [  
    "Permissions.PriceList.View",
    "Permissions.PriceList.Insert",
    "Permissions.PriceList.Edit",
    "Permissions.PriceList.Delete"
  ],
  Task: [  
    "Permissions.Task.View",
    "Permissions.Task.Insert",
    "Permissions.Task.Edit",
    "Permissions.Task.Start",
    "Permissions.Task.Complete"
  ],
  Email: [  
    "Permissinos.Email.View",
    "Permissinos.Email.Edit"
  ],
}

export const ALL_PERMISSIONS = [
  "Permissions.Tenant.View",
  "Permissions.Tenant.Insert",
  "Permissions.Tenant.Edit",
  "Permissions.Tenant.Delete",
  "Permissions.Employee.View",
  "Permissions.Employee.Insert",
  "Permissions.Employee.Edit",
  "Permissions.Employee.Delete",
  "Permissions.Country.View",
  "Permissions.Attachment.View",
  "Permissions.Attachment.Downlaod",
  "Permissions.Attachment.Insert",
  "Permissions.Attachment.Delete",
  "Permissions.City.View",
  "Permissions.State.View",
  "Permissions.User.View",
  "Permissions.User.Insert",
  "Permissions.User.Edit",
  "Permissions.User.Delete",
  "Permissions.Zone.View",
  "Permissions.Zone.Insert",
  "Permissions.Zone.Edit",
  "Permissions.Zone.Delete",
  "Permissions.Role.View",
  "Permissions.Role.Insert",
  "Permissions.Role.Edit",
  "Permissions.Role.Delete",
  "Permissions.Vehicle.View",
  "Permissions.Vehicle.Insert",
  "Permissions.Vehicle.Edit",
  "Permissions.Vehicle.Delete",
  "Permissions.Customer.View",
  "Permissions.Customer.Insert",
  "Permissions.Customer.Edit",
  "Permissions.Customer.Delete",
  "Permissions.Warehouse.View",
  "Permissions.Warehouse.Insert",
  "Permissions.Warehouse.Edit",
  "Permissions.Warehouse.Delete",
  "Permissions.Shipment.View",
  "Permissions.Shipment.Insert",
  "Permissions.Shipment.Edit",
  "Permissions.Shipment.Delete",
  "Permissions.PriceList.View",
  "Permissions.PriceList.Insert",
  "Permissions.PriceList.Edit",
  "Permissions.PriceList.Delete",
  "Permissions.Task.View",
  "Permissions.Task.Insert",
  "Permissions.Task.Edit",
  "Permissions.Task.Start",
  "Permissions.Task.Complete",
  "Permissions.Task.Operation",
  "Permissinos.Email.View",
  "Permissinos.Email.Edit",
];
