import { ALL_PERMISSIONS, userPermissions } from "./permission-maps";

const hasPermission = ({ requiredScopes }) => {
  //does user permissions contain all required scopes for this component?
  return requiredScopes.every((scope) => {
    return userPermissions.indexOf(scope) >= 0 ? true : false;
  });
};

export default function PermissionsGate({ children, requiredScopes = [] }) {
  const permissionGranted = hasPermission({ ALL_PERMISSIONS, requiredScopes });
  if (!permissionGranted) return <></>;

  return <>{children}</>;
}
