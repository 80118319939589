import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getJwt } from "./authService";

const ProtectedRoute = ({ isAuth, element, path }) => {
  const location = useLocation();

  if (!getJwt()) {
    return <Navigate to="/authentication/sign-in" state={{ from: location }} replace />;
  }

  return element;
};

export default ProtectedRoute;
