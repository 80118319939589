import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

axios.defaults.baseURL = "http://alshahbatech-007-site1.atempurl.com/api";
// axios.defaults.baseURL = "http://192.168.1.15:5000/api";

axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get("SQUserToken")}`;

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const isExpectedError =
      error.response && error.response.status >= 400 && error.response.status < 500;

    error.isExpectedError = isExpectedError;

    if (!isExpectedError) {
      toast.error("An unexpected error occurrred.");
    } else {
      if (error.response.status === 401 && Cookies.get("SQUserToken")) {
        toast.error("Your session is expired please logout and re-sign in.");
      } else if (error.response.status === 403) {
        toast.error("You are not authorized to do this action.");
      } else if (error.response.status === 404) {
        toast.error("Not Found!");
      } else if (!(error.response.data?.length !== 0)) {
        //if data is undefined or data length equal to 0 wather it's a string or array
        toast.error("An unexpected error occurred.");
      } else {
        toast.error(
          typeof error.response.data === "string" ? error.response.data : error.response.data[0]
        );
      }
    }

    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
