import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "react-toastify/dist/ReactToastify.min.css";
import { toast } from "react-toastify";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

const toastOptions = {
  autoClose: 2000,
  hideProgressBar: true,
  pauseOnHover: true,
  progress: 0.2,
};
toast.configure(toastOptions);

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
