import React from "react";
import Icon from "@mui/material/Icon";
import Crop32Icon from "@mui/icons-material/Crop32";
import { Navigate } from "react-router-dom";

const Dashboard = React.lazy(() => import("./pages/dashboard"));
const Receivers = React.lazy(() => import("./pages/receivers"));
const Tasks = React.lazy(() => import("./pages/tasks"));
const Roles = React.lazy(() => import("./pages/roles"));
const Tenants = React.lazy(() => import("./pages/tenants"));
const Employees = React.lazy(() => import("./pages/employees"));
const Zones = React.lazy(() => import("./pages/zones"));
const Users = React.lazy(() => import("./pages/users"));
const Warehouse = React.lazy(() => import("./pages/warehouse"));
const Vehicle = React.lazy(() => import("./pages/vehicle"));
const Customers = React.lazy(() => import("./pages/customers"));
const PriceList = React.lazy(() => import("./pages/price-list"));
const CreatePriceList = React.lazy(() => import("./pages/price-list/Data Form/CreatePriceList"));
const EditPriceList = React.lazy(() => import("./pages/price-list/Data Form/EditPriceList"));
const Shipments = React.lazy(() => import("./pages/shipments"));
const SignIn = React.lazy(() => import("./auth/sign-in"));
const Logout = React.lazy(() => import("./auth/log-out"));
const ForgetPassword = React.lazy(() => import("auth/reset-password/cover"));

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    protected: true,
  },
  {
    type: "collapse",
    name: "Tasks",
    key: "tasks",
    icon: <Icon fontSize="small">task</Icon>,
    route: "/tasks",
    component: <Tasks />,
    protected: true,
    requiredScopes: ["Permissions.PriceList.View"],
  },
  {
    type: "collapse",
    name: "Shipments",
    key: "shipments",
    icon: <Icon fontSize="small">directions_boat</Icon>,
    route: "/shipments",
    component: <Shipments />,
    protected: true,
    requiredScopes: ["Permissions.Shipment.View"],
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/customers",
    component: <Customers />,
    protected: true,
    requiredScopes: ["Permissions.Customer.View"],
  },
  {
    type: "collapse",
    name: "Receivers",
    key: "receivers",
    icon: <Icon fontSize="small">call_received</Icon>,
    route: "/receivers",
    component: <Receivers />,
    protected: true,
  },
  {
    type: "collapse",
    name: "Tenants",
    key: "tenants",
    icon: <Icon fontSize="small">business</Icon>,
    route: "/tenants",
    component: <Tenants />,
    protected: true,
    requiredScopes: ["Permissions.Tenant.View"],
  },
  {
    type: "collapse",
    name: "Employees",
    key: "employees",
    icon: <Icon fontSize="small">badge</Icon>,
    route: "/employees",
    component: <Employees />,
    protected: true,
    requiredScopes: ["Permissions.Employee.View"],
  },
  {
    type: "collapse",
    name: "Price List",
    key: "price-list",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/price-lists",
    component: <PriceList />,
    protected: true,
    requiredScopes: ["Permissions.PriceList.View"],
  },
  {
    type: "title",
    name: "Create Price List",
    key: "create-price-list",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/price-lists/create",
    component: <CreatePriceList />,
    protected: true,
    requiredScopes: ["Permissions.PriceList.Insert"],
  },
  {
    type: "title",
    name: "Price Lists",
    key: "pricel-lists-redirect",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/edit",
    component: <Navigate to="/price-lists" />,
    protected: true,
  },
  {
    type: "title",
    name: "Edit Price List",
    key: "edit-price-list",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/price-lists/edit/:id",
    component: <EditPriceList />,
    protected: true,
    requiredScopes: ["Permissions.PriceList.Edit"],
  },
  {
    type: "collapse",
    name: "Vehicle",
    key: "vehicle",
    icon: <Icon fontSize="small">local_shipping</Icon>,
    route: "/vehicle",
    component: <Vehicle />,
    protected: true,
    requiredScopes: ["Permissions.Vehicle.View"],
  },
  {
    type: "collapse",
    name: "Warehouse",
    key: "warehouse",
    icon: <Icon fontSize="small">warehouse</Icon>,
    route: "/warehouse",
    component: <Warehouse />,
    protected: true,
    requiredScopes: ["Permissions.Warehouse.View"],
  },
  {
    type: "collapse",
    name: "Zones",
    key: "zones",
    icon: <Crop32Icon fontSize="small" />,
    route: "/zones",
    component: <Zones />,
    protected: true,
    requiredScopes: ["Permissions.Zone.View"],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/users",
    component: <Users />,
    protected: true,
    requiredScopes: ["Permissions.User.View"],
  },
  {
    type: "collapse",
    name: "Roles",
    key: "roles",
    icon: <Icon fontSize="small">security</Icon>,
    route: "/roles",
    component: <Roles />,
    protected: true,
    requiredScopes: ["Permissions.Role.View"],
  },
  {
    type: "title",
    name: "logout",
    key: "logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/logout",
    component: <Logout />,
    protected: true,
  },
  {
    type: "title",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    protected: false,
  },
  {
    type: "title",
    name: "forget password",
    key: "forget-password",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/forget-password",
    component: <ForgetPassword />,
    protected: false,
  },
];

export default routes;
